<template>
  <div class="blog-wrapper p-1">
    <MenuComponent />
    <b-row>
      <b-col sm="1"></b-col>
      <b-col sm="10">
        <section class="faq-contact">
          <b-card no-body class="card-statistics">
            <b-carousel
              id="carousel-interval"
              controls
              indicators
              :interval="2000"
            >
              <div v-for="banner in banners" v-bind:key="banner.id">
                <b-carousel-slide
                  :img-src="getUrl + 'penyimpanan/banner/' + banner.photo"
                />
              </div>
            </b-carousel>
            <b-card-body>
              <b-row>
                <b-col xl="3" sm="4" class="mb-1 mb-xl-0">
                  <BCard style="background-color: antiquewhite" class="mb-0">
                    <b-media no-body>
                      <b-media-aside class="mr-2">
                        <b-avatar size="48" variant="info">
                          <feather-icon size="24" icon="UserIcon" />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto text-center">
                        <h4 class="font-weight-bolder mb-0">56.032</h4>
                        <b-card-text class="font-small-3 mb-0">
                          Pengguna
                        </b-card-text>
                      </b-media-body>
                    </b-media>
                  </BCard>
                </b-col>
                <b-col xl="3" sm="4" class="mb-1 mb-xl-0">
                  <BCard style="background-color: antiquewhite" class="mb-0">
                    <b-media no-body>
                      <b-media-aside class="mr-2">
                        <b-avatar size="48" variant="danger">
                          <feather-icon size="24" icon="BookOpenIcon" />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto text-center">
                        <h4 class="font-weight-bolder mb-0">782.893</h4>
                        <b-card-text class="font-small-3 mb-0">
                          TryOut
                        </b-card-text>
                      </b-media-body>
                    </b-media>
                  </BCard>
                </b-col>
                <b-col xl="3" sm="4" class="mb-1 mb-xl-0">
                  <BCard style="background-color: antiquewhite" class="mb-0">
                    <b-media no-body>
                      <b-media-aside class="mr-2">
                        <b-avatar size="48" variant="success">
                          <feather-icon size="24" icon="CheckSquareIcon" />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto text-center">
                        <h4 class="font-weight-bolder mb-0">12.893</h4>
                        <b-card-text class="font-small-3 mb-0">
                          Alumni
                        </b-card-text>
                      </b-media-body>
                    </b-media>
                  </BCard>
                </b-col>
                <b-col xl="3" sm="4" class="mb-1 mb-xl-0">
                  <BCard style="background-color: antiquewhite" class="mb-0">
                    <b-media no-body>
                      <b-media-aside class="mr-2">
                        <b-avatar size="48" variant="warning">
                          <feather-icon size="24" icon="ListIcon" />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto text-center">
                        <h4 class="font-weight-bolder mb-0">93</h4>
                        <b-card-text class="font-small-3 mb-0">
                          Kampus MoU
                        </b-card-text>
                      </b-media-body>
                    </b-media>
                  </BCard>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </section>

        <section class="faq-contact">
          <b-row class="match-height text-center">
            <b-col cols="12">
              <h2>Fitur dan Aktivitas</h2>
              <b-card-text class="mb-1">
                Maksimalkan keuntungan dengan dukungan fitur dan aktivitas
                tersedia kapanpun dibutuhkan
              </b-card-text>
            </b-col>
            <b-col
              sm="4"
              v-for="fitur_activity in fituractivities"
              v-bind:key="fitur_activity.id"
            >
                <b-card class="shadow-none py-1 faq-contact-card" style="background-color: #e6cce6">
                  <b-link :href="'/fitur_activity/detail/' + fitur_activity.id">
                    <b-img
                      :src="
                        getUrl +
                        'penyimpanan/fituractivity/' +
                        fitur_activity.photo
                      "
                    >
                    </b-img>
                    <p></p>
                    <h4>{{ fitur_activity.title }}</h4>
                    <span class="text-body">{{
                      fitur_activity.description
                    }}</span>
                  </b-link>
                </b-card>
            </b-col>
          </b-row>
        </section>

        <section class="faq-contact">
          <b-row class="match-height text-center">
            <b-col cols="12">
              <h2>Penawaran Harga</h2>
              <b-card-text class="mb-3">
                Berikut adalah Harga dari kursus yang kami sediakan pada layanan
              </b-card-text>
            </b-col>
          </b-row>

          <b-row class="pricing-card">
              <b-row class="match-height">
                <b-col md="4" v-for="paket in dataPaket" v-bind:key="paket.id">
                  <b-card align="center">
                    <!-- img -->
                    <b-img
                      class="mb-2 mt-1"
                      alt="basic svg img"
                      :src="getUrl + 'penyimpanan/paket/' + paket.photo"
                      style="width: 50px"
                    />
                    <!--/ img -->
                    <h3>{{ paket.title }}</h3>
                    <b-card-text> {{ paket.description }} </b-card-text>

                    <!-- annual plan -->
                    <div class="annual-plan">
                      <div class="plan-price mt-2">
                        <small
                          class="font-medium-1 font-weight-bold text-primary"
                          >Rp.
                        </small>
                        <span
                          class="
                            pricing-basic-value
                            font-weight-bolder
                            text-primary
                          "
                          >{{ formatPrice(paket.price) }}</span
                        >
                        <small
                          class="
                            pricing-duration
                            text-body
                            font-medium-1 font-weight-bold
                          "
                        >
                          /bulan</small
                        >
                      </div>
                    </div>
                    <!--/ annual plan -->

                    <!-- plan benefit -->
                    <b-list-group class="list-group-circle text-left">
                      <b-list-group-item>
                        {{ paket.list_1 }}
                      </b-list-group-item>
                      <b-list-group-item>
                        {{ paket.list_2 }}
                      </b-list-group-item>
                      <b-list-group-item>
                        {{ paket.list_3 }}
                      </b-list-group-item>
                      <b-list-group-item>
                        {{ paket.list_4 }}
                      </b-list-group-item>
                      <b-list-group-item>
                        {{ paket.list_5 }}
                      </b-list-group-item>
                      <b-list-group-item>
                        {{ paket.list_6 }}
                      </b-list-group-item>
                      <b-list-group-item>
                        {{ paket.list_7 }}
                      </b-list-group-item>
                      <b-list-group-item>
                        {{ paket.list_8 }}
                      </b-list-group-item>
                      <b-list-group-item>
                        {{ paket.list_9 }}
                      </b-list-group-item>
                      <b-list-group-item>
                        {{ paket.list_10 }}
                      </b-list-group-item>
                    </b-list-group>
                    <!--/ plan benefit -->

                    <!-- buttons -->
                    <b-link :href="paket.link">
                      <b-button block class="mt-2" variant="primary">
                        Beli Paket
                      </b-button>
                    </b-link>
                  </b-card>
                </b-col>
              </b-row>
          </b-row>
        </section>

        <section class="faq-contact">
          <b-row class="match-height"
          v-for="contact in contacts"
            v-bind:key="contact.id">
            <b-col cols="12" class="text-center">
              <h2>Jika ada Pertanyaan?</h2>
              <b-card-text class="mb-3">
                Dapat Hubungi Kontak Kami Disini
              </b-card-text>
            </b-col>
            <b-col sm="3">
              <b-card class="shadow-none py-1 faq-contact-card text-center" style="background-color: #e6cce6">
                <b-avatar
                  size="42"
                  rounded
                  variant="light-primary"
                  class="mb-2"
                >
                  <feather-icon icon="MailIcon" size="18" />
                </b-avatar>
                <h6>{{ contact.email }}</h6>
                <span class="text-body">Team Optimal</span>
              </b-card>
            </b-col>
            <b-col sm="3">
              <b-card class="shadow-none py-1 faq-contact-card text-center" style="background-color: #e6cce6">
                <b-avatar
                  size="42"
                  rounded
                  variant="light-primary"
                  class="mb-2"
                >
                  <feather-icon icon="MapPinIcon" size="18" />
                </b-avatar>
                <h6>{{ contact.alamat }}</h6>
                <!-- <span class="text-body">Team Optimal</span> -->
              </b-card>
            </b-col>
            <b-col sm="3">
              <b-card class="shadow-none py-1 faq-contact-card text-center" style="background-color: #e6cce6">
                <b-avatar
                  size="42"
                  rounded
                  variant="light-primary"
                  class="mb-2"
                >
                  <feather-icon icon="PhoneCallIcon" size="18" />
                </b-avatar>
                <h6>{{ contact.telepon }}</h6>
                <span class="text-body">Team Optimal</span>
              </b-card>
            </b-col>
            <b-col sm="3">
              <b-card class="shadow-none faq-contact-card text-center" style="background-color: #e6cce6">
                <b-avatar
                  size="42"
                  rounded
                  variant="light-primary"
                  class="mb-2"
                >
                  <feather-icon icon="AlertCircleIcon" size="18" />
                </b-avatar>
                <h6>{{ contact.catatan }}</h6>
                <!-- <span class="text-body">Team Optimal</span> -->
              </b-card>
            </b-col>
          </b-row>
        </section>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import MenuComponent from "@/views/Menu.vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import { BCarousel, BCarouselSlide } from "bootstrap-vue";
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    MenuComponent,
    AppTimeline,
    BCarousel,
    BCol,
    BCarouselSlide,
    AppTimelineItem,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  data: () => ({
    selected: "left",
    selected2: "right",
    level: localStorage.getItem("Ulevel"),
    fituractivities: [],
    banners: [],
    contacts: [],
    dataPaket: [],
    getUrl: "",
  }),

  async mounted() {
    await this.getDataFiturActivity();
    await this.getDataBanners();
    await this.getDataContacts();
    await this.getDataPakets();
    this.getUrl = process.env.VUE_APP_URL;
  },

  methods: {
    async getDataFiturActivity() {
      await this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "fituractivities/view")
        .then((res) => {
          this.fituractivities = res.data.data;
        });
    },
    async getDataBanners() {
      await this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "banners/view")
        .then((res) => {
          this.banners = res.data.data;
        });
    },

    async getDataContacts() {
      await this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "contacts/view")
        .then((res) => {
          this.contacts = res.data.data;
        });
    },

    async getDataPakets() {
      await this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "pakets/view")
        .then((res) => {
          this.dataPaket = res.data.data;
        });
    },
    formatPrice(value) {
      if (value != null) {
        const val = Number(value).toFixed(2).replace(/\.00$/, "");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }
    },
  },
};
</script>
